import { getCart } from "@/server/api/cartActions"
import { useQuery } from "@tanstack/react-query"

export const useCart = () => {
  return useQuery({
    queryKey: ["get-cart"],
    queryFn: getCart,
    refetchOnWindowFocus: false,
  })
}
