export const Cart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={21}
    viewBox="0 0 22 21"
    fill="none"
  >
    <path
      d="M9.89962 7.21655H17.0346C17.5472 7.21655 17.8036 7.21655 17.9912 7.29929C18.8213 7.66528 18.4502 8.58738 18.3105 9.25005C18.2854 9.3691 18.2038 9.47043 18.0894 9.52475C17.6149 9.74989 17.2825 10.177 17.1953 10.6736L16.7091 13.4423C16.4952 14.6604 16.4221 16.4013 15.3546 17.2595C14.5713 17.8829 13.4428 17.8829 11.1857 17.8829H9.434C7.17693 17.8829 6.04841 17.8829 5.26518 17.2595C4.19765 16.4013 4.12452 14.6604 3.9106 13.4423L3.42439 10.6736C3.33717 10.177 3.00484 9.74989 2.53033 9.52475C2.41591 9.47043 2.33433 9.3691 2.30923 9.25005C2.16959 8.58738 1.79845 7.66528 2.62855 7.29929C2.81621 7.21655 3.07252 7.21655 3.58512 7.21655H6.61767"
      stroke="white"
      strokeWidth="1.23073"
      strokeLinecap="round"
    />
    <path
      d="M11.9509 10.4983H8.66895"
      stroke="white"
      strokeWidth="1.23073"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.79736 9.67792L8.66907 3.11401M12.7715 3.11401L14.8227 7.21646"
      stroke="white"
      strokeWidth="1.23073"
      strokeLinecap="round"
    />
  </svg>
)
