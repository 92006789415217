"use client"
import React, { useEffect, useState } from "react"
import { useRouter } from "next/navigation"

import { memo } from "react"
import { Star } from "@/app/(root)/_components/bento-grid/icons"
import { addItemToCart, DeviceWithQty } from "@/server/api/cartActions"
import { useQueryClient } from "@tanstack/react-query"

import { toast } from "sonner"
import Loader from "./button-loader"
import { Cart } from "./icons/cart"
import { ProductCardImage } from "./icons/product-image"
import { HeartIcon } from "./icons/heart-icon"
import { useCart } from "@/queries/useCart"
import Link from "next/link"

export type ProductCardType = {
  image?: { url: string }[]
  device_name: string
  rating: string
  reviews: string
  description?: string
  payable: string
}

const ProductCard = memo(
  ({
    product,
    imgBgColor = "#F7F7F7",
    addToCart = true,
  }: {
    product: DeviceWithQty
    res?: number
    imgBgColor?: string
    addToCart?: boolean
  }) => {
    const router = useRouter()
    const [loading, setLoading] = useState(false)

    const queryClient = useQueryClient()

    const { data: cart } = useCart()

    const findItemQuantityById = (itemId: string) => {
      if (cart?.items?.length > 0) {
        return cart?.items.reduce((acc: number, item: DeviceWithQty) => {
          return item?._id === itemId ? acc + (item?.quantity || 0) : acc
        }, 0)
      }
      return 0
    }

    const [quantity, setQuantity] = useState<number>(
      findItemQuantityById(product?._id!)
    )

    useEffect(() => {
      const qty = findItemQuantityById(product._id!)
      setQuantity(qty)
    }, [product?._id!, cart?.items])

    const [isHovered, setIsHovered] = useState(false)
    const [randomIndex, setRandomIndex] = useState(0)

    const handleMouseEnter = () => {
      setIsHovered(true)
      const maxIndex = product?.image?.length - 1
      if (maxIndex > 0) {
        const randIdx = Math.floor(Math.random() * maxIndex) + 1
        setRandomIndex(randIdx)
      }
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    const handleAddToCart = async () => {
      setLoading(true)
      try {
        const utmData = JSON.parse(sessionStorage.getItem("utm_data")!)

        const res = await addItemToCart(product?._id!, 1, utmData)
        if (typeof window !== "undefined") {
          window.gtag("event", "add_to_cart", {
            currency: "INR",
            value: Number(product?.payable) / 100,
            items: [
              {
                item_id: product?.item_code,
                item_name: product?.device_name,
                price: Number(product?.payable) / 100,
                quantity: product?.qty,
              },
            ],
          })
          window.fbq("track", "AddToCart", {
            content_ids: product?.item_code,
            content_name: product?.device_name,
            content_type: "product",
            value: Number(product?.payable) / 100,
            currency: "INR",
          })
        }
        queryClient
          .invalidateQueries({
            queryKey: ["get-cart"],
            exact: true,
            refetchType: "active",
          })
          .then()
        setQuantity(1)
        toast.success("Item added to cart!")
      } catch (error) {
        toast.error("Failed to add item to cart")
      } finally {
        setLoading(false)
      }
    }

    return (
      <div className="lg:max-w-60 lg:max-h-80 lg:min-w-40  lg:min-h-40 lg:w-60 lg:h-80 w-[11rem] xs:w-40 sm:w-48 md:w-52 max-xs:w-40  xs:h-80 max-h-72 my-2 px-1  h-fit  sm:mb-10">
        {/* {JSON.stringify(product)} */}

        <Link
          onMouseEnter={() => router.prefetch(`/products/${product?.slug}`)}
          href={`/products/${product?.slug}`}
          className="py-14 cursor-pointer relative px-2 w-full h-[20vh] lg:h-[78%] flex justify-center items-center  rounded-2xl lg:rounded-[1.5rem]"
          style={{ backgroundColor: imgBgColor }}
        >
          <div className="w-full h-[15%] absolute top-[1%] flex justify-between items-center px-[4%]">
            {/* <NewBadge text="New" /> */}
            {product?.ownership === "owned" ? (
              <NewBadge text="Refurbished" />
            ) : (
              <NewBadge text="New" />
            )}
            {/* <WishListBadge /> */}
          </div>
          <div className="trust-logo-wrapper">
            <img src="/media/trust-logo-small.webp" alt="verified" />
            <div className="shine"></div>
          </div>

          {product?.image ? (
            <img
              src={
                isHovered
                  ? product?.image?.[randomIndex].url
                  : product?.image?.[0].url ?? "/media/product-img/dell1.png"
              }
              alt="product"
              loading="lazy"
              fetchPriority="low"
              className="w-32 h-32 lg:min-w-40 lg:w-60 lg:h-36 mt-4 object-contain sm:object-cover"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          ) : (
            <ProductCardImage className="w-60 h-36" />
          )}
        </Link>

        <div className="flex flex-col gap-0 pt-1.5 lg:pt-4 ">
          <Link
            href={`/products/${product?.slug}`}
            className="flex items-center lg:justify-between w-full px-2"
          >
            <h2 className="text-base lg:text-lg whitespace-nowrap font-gilroySemiBold truncate">
              {product?.device_name?.length! > 16
                ? product?.device_name?.slice(0, 16) + "..."
                : product?.device_name}{" "}
            </h2>

            <div className="hidden lg:flex items-center gap-1 justify-center text-xs lg:text-sm font-gilroyMedium">
              <Star className="size-3" />
              <div className="flex items-end gap-1">
                <span>{product?.reviewAggregates?.overallRating}</span>
                <span className="text-[#727171]">
                  ({product?.reviewAggregates?.overallReviews})
                </span>
              </div>
            </div>
          </Link>

          {product?.description ? (
            <Link
              href={`/products/${product?.slug}`}
              className="text-[#727171] text-xs font-gilroySemiBold truncate lg:text-pretty lg:mt-1 px-2"
            >
              {product?.description.length > 68
                ? product.description.slice(0, 68) + "..."
                : product.description}
            </Link>
          ) : null}

          <div className="flex items-center justify-between pr-1 lg:mt-3 px-2 my-1 mb-2 lg:my-0">
            <span className="text-[0.9rem] md:text-base lg:text-xl  font-gilroyBold ">
              ₹{((product?.payable || 0) / 100)?.toLocaleString()}
            </span>

            <div className="sm:hidden flex items-center gap-1 justify-center text-xs lg:text-sm font-gilroyMedium">
              <Star className="size-3" />
              <div className="flex items-end gap-1">
                <span>{product?.reviewAggregates?.overallRating}</span>
                <span className="text-[#727171]">
                  ({product?.reviewAggregates?.overallReviews})
                </span>
              </div>
            </div>

            {quantity > 0 ? (
              <div
                onClick={() => router.push("/cart")}
                className="hidden lg:flex h-fit w-fit py-1 px-1.5 xl:py-2 xl:px-5 whitespace-nowrap bg-white cursor-pointer text-black border border-black font-gilroySemiBold text-[10px] sm:text-xs justify-center items-center mb-0.5 rounded-3xl gap-x-1"
              >
                Go to Cart
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleAddToCart()
                }}
                disabled={product?.qty === 0}
                className="hidden lg:flex h-fit w-fit py-2 px-1.5 xl:py-2 xl:px-3 whitespace-nowrap bg-black cursor-pointer text-white font-gilroySemiBold text-[10px] sm:text-xs justify-center items-center mb-0.5 rounded-3xl gap-x-1"
              >
                <Cart className="size-4" />
                {loading ? (
                  <div className="px-5">
                    <Loader />
                  </div>
                ) : product?.qty === 0 ? (
                  "Out of Stock"
                ) : (
                  "Add to cart"
                )}
              </button>
            )}
          </div>
          {addToCart ? (
            quantity > 0 ? (
              <div
                onClick={() => router.push("/cart")}
                className="flex lg:hidden h-9  xs:h-8 w-full bg-black cursor-pointer text-white font-gilroyMedium text-sm justify-center items-center mb-0.5 rounded-md gap-x-1"
              >
                Go to cart!!
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleAddToCart()
                }}
                className="flex lg:hidden h-9 xs:h-8 w-full bg-black cursor-pointer text-white font-gilroyMedium text-sm justify-center items-center mb-0.5 rounded-md gap-x-1"
              >
                <Cart className="size-5" />
                {loading ? (
                  <div className="px-5">
                    <Loader />
                  </div>
                ) : product?.qty === 0 ? (
                  "Out of Stock"
                ) : (
                  "Add to cart"
                )}
              </button>
            )
          ) : null}
        </div>
      </div>
    )
  }
)

ProductCard.displayName = "ProductCard"

export { ProductCard }

const NewBadge = ({ text }: { text: string }) => (
  <span className="border border-[#EFEFEF] bg-white px-2 lg:px-3 py-0 lg:py-0.5 rounded-3xl flex justify-center items-center text-xxs lg:text-sm font-gilroyMedium">
    {text}
  </span>
)

const WishListBadge = () => (
  <div className="bg-black rounded-full size-6 lg:size-9 flex items-center justify-center">
    <HeartIcon className="size-4 lg:size-6" />
  </div>
)
