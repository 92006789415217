export const HeartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M17.0732 5.00448C14.9932 3.72865 13.1779 4.24279 12.0873 5.06177C11.6401 5.39758 11.4166 5.56548 11.285 5.56548C11.1535 5.56548 10.93 5.39758 10.4827 5.06177C9.39222 4.24279 7.57685 3.72865 5.4969 5.00448C2.7672 6.67889 2.14953 12.2028 8.44589 16.8632C9.64515 17.7508 10.2448 18.1946 11.285 18.1946C12.3253 18.1946 12.9249 17.7508 14.1242 16.8632C20.4205 12.2028 19.8028 6.67889 17.0732 5.00448Z"
      stroke="white"
      strokeWidth="1.16343"
      strokeLinecap="round"
    />
  </svg>
)
